<template>
    <comp-table title="供应商商品销售报表" :columns="columns" table-api="/gateway/apps/o2o/api/terminal/goods/getSupplierGoodsSalesReport" :dataBefore="onDataBefore" @on-change-search="onChangeSearch">
        <template v-slot:search="data">
            <Select v-model="data.search.supplierId" filterable style="width:300px" transfer placeholder="供应商">
                <Option v-for="item in suppliers" :value="item.supplierId" :key="item.supplierId">{{ item.supplierName }}</Option>
            </Select>
            <RadioGroup v-model="data.search.type" type="button">
                <Radio label="全部"></Radio>
                <Radio label="本年"></Radio>
                <Radio label="本月"></Radio>
                <Radio label="本日"></Radio>
                <Radio label="自定义"></Radio>
            </RadioGroup>
            <DatePicker v-if="isCustomize" v-model="data.search.date" type="daterange" style="width: 250px" placeholder="时间段"></DatePicker>
        </template>
    </comp-table>
</template>

<script>
import CompTable from "../../../jointly/components/comp-table.vue"

export default {
    components: { CompTable },

    data() {
        return {
            groups: null,
            suppliers: null,
            terminals: null,

            isCustomize: false,

            columns: [
                {
                    title: "商品",
                    key: "goodsName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "数量",
                    key: "num",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "销售额",
                    key: "sales",
                    minWidth: 200,
                    align: "center",
                },
            ],
        }
    },

    mounted() {
        this.getSupplier()
    },

    methods: {
        getSupplier() {
            this.$get("/gateway/apps/o2o/api/terminal/goods/listGroupSupplierByGroupId").then(res => {
                if (res.code == 200) {
                    this.suppliers = res.dataList
                }
            })
        },

        // 监听数据前置事件
        onDataBefore(data) {
            const res = new Object(null)

            if (data.type == "自定义") {
                if (!data.date) {
                    this.$Message.error({
                        content: "请选择时间",
                        background: true,
                    })

                    return false
                }

                res.startDate = data.date[0]
                res.endDate = data.date[1]
            } else if (data.type != "全部") {
                res.date = { 本月: "MONTH", 本年: "YEAR", 本日: "DAY" }[data.type]
            }

            data.supplierId && (res.supplierId = data.supplierId)

            return res
        },

        onChangeSearch(evt) {
            const { key, value } = evt.value

            if (key === "type") {
                this.isCustomize = value === "自定义"
            }
        },
    },
}
</script>
<style lang="less"></style>
